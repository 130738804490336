import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
window.Alpine = Alpine;
window.htmx = htmx;

window.Swiper = Swiper;

if (window.htmx) {
    import('htmx-ext-head-support').then(() => {
        console.log('htmx-ext-head-support loaded');
    });
}
